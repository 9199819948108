/* src/ScrollingText.css */

  
  .scrolling-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 20s linear infinite;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(20%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .pathway_extreme_header_info {
    color: rgb(1, 13, 255);
    font-family: "Pathway Extreme", sans-serif;
    font-optical-sizing: auto;
    font-weight: 450;
    font-style: normal;
    font-variation-settings: "wdth" 75;
    /* line-height: 2.2rem; */
    margin-right: 0;
    margin-left: 0;
    font-size: 1rem;
  }
