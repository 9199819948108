.instructionsHeading{
    color: blue;
    text-align: start;
    padding-left: 3%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 700;
}

.textCut{
    text-decoration: line-through;
    color: black;
}

.instructionsHeading span{
    color: orange;
}

.instructionsContent{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
    line-height: 2.5rem;
    padding-left: 3rem;
    
}

.submitDigestText{
    color: blue;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 650;
}

.submissionGuidelinesText{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: 4rem;
    
    list-style-type: disc;

    @keyframes blink {
      0% { opacity: 1; }
      50% { opacity: 0; }
      100% { opacity: 1; }
  }

  .blink {
      animation: blink 2s infinite;
      color: red; /* You can change the color if you want */
  }

  
}
.headd{
  color: blue;
    text-align: start;
    padding-left: 3%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
}

.helpsec{
  text-align: center;
  padding: 1%;
  font-weight: bolder;
  color: #05006c;
  font-size: large;
}
.submissionGuidelinesText p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
    line-height: 2.5rem;
}
.submissionGuidelinesTextConditions{
  padding-left: 1.5rem;
}

.submissionTemplate button {
  align-items: center;
  appearance: none;
  
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 1rem;
  font-weight: 800;
  justify-content: center;
  line-height: 1rem;
  margin-left: 3rem;
  min-height: 1rem;
  outline: none;
  overflow: visible;
  padding-left: 2rem;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .submissionTemplate button {
    padding: 1rem 1.6rem;
  }
}

.submissionTemplate button:before,
.submissionTemplate button:after {
  border-radius: 1.5rem;
}

.submissionTemplate button:before {
  background-color: rgba(249, 58, 19, .32);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.submissionTemplate button:after {
  background-color: initial;
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.submissionTemplate button:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
}

    /* .submission-template button:active:not(:disabled) {
    color: #ccc;
    } */

.submissionTemplate button:active:not(:disabled):after {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.submitButton{
    position: absolute;
    color: white;
    background-color: blue;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem;
    box-shadow: 0 0 0.1rem 0.1rem rgb(0,0,0);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    min-height:1.5rem; 
    min-width: 4rem;
}

.submitButton:hover{
    background-color: rgb(1, 1, 117);
    transition: 0.5s;
}

.submitButton:focus {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 4px #05006c;
    transition: 0.5s;
}

.button:active {
    background-color: rgb(1, 1, 117);
}
.submitButtonArea{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}
