.container {
  width: 100%;
  background-color: white;
}

.header_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  
}

.header_main {
  display: flex;
  align-items: center;
}

.main_conference_logo img{
  height: 6.8rem;
  width: auto;
  object-fit: contain;
}
.nits_logo img,
.location_ieee_logo img {
  height: 5rem;
  width: auto;
  object-fit: contain;
} 

.pathway_extreme_header_info {
  color: green;
  font-family: "Pathway Extreme", sans-serif;
  font-optical-sizing: auto;
  font-weight: 750;
  font-style: normal;
  font-variation-settings: "wdth" 75;
  padding-left: 4.5rem;
}

.pathway_extreme_header_info h2 {
  font-size: 1.4rem;
  margin: 0;
  text-align: center;
}

.pathway_extreme_header_info span {
  color: orange;
}

.pathway_extreme_header_subinfo {
  color: blue;
  font-size: 1.2rem;
  text-align: center;
}

.important_logos {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 800px) {
  .container {
    overflow-x: auto;
    width: auto;
  }

  .header_content {
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    /* padding: 0.1rem; */
    
  }
  .pathway_extreme_header_info h2{
    font-size: 0.66rem;
  }

  .main_conference_logo img{
    height: 6rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    width: 6rem;
    object-fit: contain;
  }

  .pathway_extreme_header_subinfo h5{
    font-size: 0.6rem;
  }

  .nits_logo{
    padding: 0;
    padding-left: 1rem;
  }

  .nits_logo img{
    height: 3.5rem;
    padding-bottom: 1rem;
  }
  /* .header_content {
    flex-wrap: nowrap;
    justify-content: flex-start;
    min-width: max-content;
    gap: 1rem;
  }

  .header_main {
    flex-direction: row;
    align-items: center;
  }

  .main_conference_logo img,
  .nits_logo img,
  .location_ieee_logo img {
    height: 2.5rem;
  }

  .header_text {
    padding: 0;
  }

  .pathway_extreme_header_info h2 {
    font-size: 1rem;
    white-space: nowrap;
  }

  .pathway_extreme_header_subinfo {
    font-size: 0.7rem;
    white-space: nowrap;
  } */

  /* .important_logos {
    flex-direction: row;
    align-items: center;
  } */
  .important_logos .location_ieee_logo img {
    display: none;
  }
}

@media (max-width: 600px){
  .important_logos img {
    display: none;
  }
}