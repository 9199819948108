    .header{
        text-align: center;
        font-family: "Anek Tamil", sans-serif;
        font-weight: 900;
        font-style: normal;
        font-variation-settings: "wdth" 97;
        color: blue;
        font-size: 50px;
    }

    .header span {
        color: orange;
    }

    .subHeader{
        text-align: center;
        font-family: "Anek Tamil", sans-serif;
        font-weight: 900;
        font-style: normal;
        font-variation-settings: "wdth" 97;
        color: blue;
        font-size: 1.8rem;
    }

    .subHeader span {
        color: orange;
    }

    .primaryContacts{
        display: flex; 
        justify-content: center;
    }

    .mainContact{
        text-align: center;
        padding-left: 2.5rem;
        padding-right: 3.5rem;
        
    }
    .contactName {
        font-family: "Anek Tamil", sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        padding: 10px;
        
        /* Rounded corners */
        border-radius: 15px;
    
        /* Box shadow */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
        /* Optional background color for visibility */
        background-color: #fff;
    
        /* Smooth transition for hover effect */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    .contactName:hover {
        /* Move the box slightly down */
        transform: translateY(5px);
    
        /* Increase shadow for a more pronounced effect */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    }
    
    
    .contactEmail{
        font-family: "Anek Tamil", sans-serif;
        font-weight: 500;
        font-size: 1rem;
    }

    .generalQueriesText{
        font-family: "Anek Tamil", sans-serif;
        font-size: 1.5rem ;
    }

    .generalQueriesInnerText{
        font-family: "Anek Tamil", sans-serif;
        font-size: clamp(1rem, 2.5vw, 1.3rem);
        color: #17252A;
        text-align: justify;
        font-weight: 600;
    }   
    .generalQueriesInnerText a{
        font-style: italic;
        color: blue;
    }

    .generalQueriesInnerText span{
        font-style: italic;
        font-weight: 600;
    }

    .generalQueries{
        background-color: #3AAFA9;
        margin: 0 4rem 0 4rem;
        padding: 0 2rem 0 2rem;
    }

    @media (max-width: 1000px) {
        .header{
            font-size: 2rem;
        }

        .primaryContacts{
            display: flex; 
            flex-direction: column;
            justify-content: center;
        }

        .mainContact{
            padding-bottom: 3rem;
        }
    }