.anekTamilSponsors {
    text-align: center;
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    color: blue;
    font-size: 30px;

    h2 span {
        color: orange;
    }
}

.anekTamilAboutUs {
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    color: blue;
    font-size: 30px;
    padding-top: 3%;
    padding-left: 1%;
    text-align: center;

    h2 span {
        color: orange;
    }
}

.anekTamilAboutUsInfo {
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    font-size: 20px;
    color: rgb(53, 49, 49);
    padding-left: 1%;
}

.aboutUsInfo p span {
    font-weight: bold;
    font-size: 1.4rem;
}

.anekTamilAboutUsPara {
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-align:justify;
    font-variation-settings: "wdth" 97;
    font-size: 1.3rem;
    color: rgb(53, 49, 49);
    padding-left: 1%;
    padding:1rem 5rem 5rem 5rem;
    text-justify:distribute;
}

.sponsorsImg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 105px;
}

.sponsor_1 {
    width: 13%;
}

.aboutVideoSection {
    text-align: center; /* Centers iframe horizontally */
}

#about-video {
    margin: 0 auto; /* Centers iframe horizontally */
    display: block; /* Ensures iframe is treated as a block element */
}

@media screen and (max-width: 400px) {
    .anekTamilAboutUs {
        font-family: "Anek Tamil", sans-serif;
        font-optical-sizing: auto;
        font-weight: 900;
        font-style: normal;
        font-variation-settings: "wdth" 97;
        color: blue;
        font-size: 1.3rem;
        padding-top: 3%;
        padding-left: 1%;
        padding-right: 1%;
        text-align: center;
    
        h2 span {
            color: orange;
        }
    }

    .aboutUsInfo p span {
        font-weight: bold;
        font-size: .9rem;
    }
    
    .anekTamilAboutUsPara {
        font-family: "Anek Tamil", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        text-align: start;
        font-variation-settings: "wdth" 97;
        font-size: .8rem;
        color: rgb(53, 49, 49);
        padding-left: 1%;
        padding-right: 1%;
        padding:1rem .5rem .5rem .5rem;
    }
    .aboutVideoSection{
       padding-left: 12%;
        width:350px;
    }
  }
  