.header {
  h1{
    display: flex;
    justify-content: center;
    font-size: clamp(24px, 5vw, 36px);
    color: blue;
    font-family: "Anek Tamil", sans-serif;
    font-weight: 900;
    padding-bottom: 2rem;
    text-align: center;
  }
 
  span{
    color: orange;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.left, .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bg1{
  background-color: rgb(174, 251, 251);
  border-radius: 0.5rem;
}
.bg2{
  background-color: rgb(158, 245, 171);
  border-radius: 0.5rem;
}
.bg3{
  background-color: rgba(244, 134, 248, 0.373);
  border-radius: 0.5rem;
}
.bg4{
  background-color: rgb(244, 128, 89);
  border-radius: 0.5rem;
}
.bg5{
  background-color: rgb(174, 198, 251);
  border-radius: 0.5rem;
}
.bg6{
  background-color: rgb(239, 221, 103);
  border-radius: 0.5rem;
}
.bg7{
  background-color: rgb(90, 177, 248);
  border-radius: 0.5rem;
}
.lcard {
 
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  padding: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-height: 8rem;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translate(0.25rem, -0.25rem);
    box-shadow: -5px 5px 10px slategray;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: clamp(0.8rem, 1.8vw, 1.1rem);
    line-height: 1.4;
  }
}

/* Responsive adjustments */
@media screen and (min-width: 451px) and (max-width: 870px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.5rem;
  }

  .lcard {
    padding: 1.25rem;
    min-height: 7rem;

    h2 {
      font-size: clamp(0.75rem, 1.6vw, 1rem);
    }
  }
}

/* For small devices */
@media screen and (max-width: 450px) {
  .container {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0.5rem;
  }

  .lcard {
    padding: 1rem;
    min-height: 6rem;

    h2 {
      font-size: clamp(0.7rem, 4vw, 0.9rem);
    }
  }
}