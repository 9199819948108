.header{
    text-align: center;
    font-family: "Anek Tamil", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    color: blue;
    font-size: 50px;
}

.header span {
    color: orange;
}