.sponsorship{
    
    // padding-left: 400px;
    // padding-right: 400px;
    // padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left:0vw;
    img{
        object-fit:contain;
        // min-height: 100%;
        max-width: 50vw;
    }
}