.brochure{
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left:0vw;
    margin-bottom: 2rem;
    img{
        object-fit:contain;
        // min-height: 100%;
        max-width: 50vw;
    }
}