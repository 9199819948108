
.TechnicalSponsors{

    padding-top: 3%;

    height: 25rem;

    .header{
        text-align: center;
            font-family: "Anek Tamil", sans-serif;
            font-optical-sizing: auto;
            font-weight: 900;
            font-style: normal;
            font-variation-settings: "wdth" 97;
            color: blue;
            font-size: 40px;
        
    }
    .icons{
        display: flex;
        justify-content: space-evenly;
                
                .logo1, .logo2, .logo3, .logo4, .logo5, .logo6 {
                    padding: 1em;
                    padding-top: 50px; 
                }
                .logo4{
                    height: 20rem;
                    width: 15rem;
                    padding-top: 2rem;
                  }
                .logo5{
                    padding-top: 0.8rem;
                    padding-left: 0.3rem;
                }
                .logo1{
                    padding-top: 1rem;
                }
                .logo6{
                    padding-top: 2rem;
                }
                @media (max-width: 1000px) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                    .logo1, .logo2, .logo3, .logo4, .logo5, .logo6 {
                        height: auto;
                        width: 100%;
                    }

                }
                @media (max-width: 768px) {
                    .logo1{
                        padding-top: 2rem;
                    }

                }
    }
}
.FinancialSponsors{
    padding-top: 0;
    height: 15rem;
    .header{
        text-align: center;
            font-family: "Anek Tamil", sans-serif;
            font-optical-sizing: auto;
            font-weight: 900;
            font-style: normal;
            font-variation-settings: "wdth" 97;
            color: blue;
            font-size: 40px;
        
    }
    .icons{
        display: flex;
        justify-content: space-evenly;
        padding-top: 3%; 
        .logo1{
            padding-top: 0.3rem;
        } 
        @media (max-width: 768px) {
            .logo1{
                padding-top: 2rem;
            }
        }    
    }

    @media (max-width: 1000px) {
        margin-top: 7rem;
        .logo1, .logo2 {
            padding: 2em;
        }
    }
    
}