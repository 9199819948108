.header{
  text-align: center;
  font-family: "Anek Tamil", sans-serif;
  
  font-style: normal;
  font-variation-settings: "wdth" 97;
  color: blue;
  font-family: Eina01,sans-serif;
  font-size: 2rem;
  font-weight: 800;
  justify-content: center;

}
.submitButton{
  position: absolute;
  color: white;
  background-color: blue;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
  box-shadow: 0 0 0.1rem 0.1rem rgb(0,0,0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  min-height:1.5rem; 
  min-width: 4rem;
}

.submitButton:hover{
  background-color: rgb(1, 1, 117);
  transition: 0.5s;
}

.submitButton:focus {
  outline-color: transparent;
  outline-style:solid;
  box-shadow: 0 0 0 4px #05006c;
  transition: 0.5s;
}

.button:active {
  background-color: rgb(1, 1, 117);
}
.submitButtonArea{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}
.submissionTemplate button {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 1rem;
  font-weight: 800;
  justify-content: center;
  line-height: 1rem;
  margin: 0;
  min-height: 1rem;
  outline: none;
  overflow: visible;
  padding: 1rem 1.5rem;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .submissionTemplate button {
    padding: 1rem 1.6rem;
  }
}

.submissionTemplate button:before,
.submissionTemplate button:after {
  border-radius: 1.5rem;
}

.submissionTemplate button:before {
  background-color: rgba(249, 58, 19, .32);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.submissionTemplate button:after {
  background-color: initial;
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.submissionTemplate button:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
}

    /* .submission-template button:active:not(:disabled) {
    color: #ccc;
    } */

.submissionTemplate button:active:not(:disabled):after {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.header span {
  color: orange;
}
.publicationHeader{
  display: flex;
  justify-content:center;
  font-size: clamp(12px,10vw,36px);
  color: blue;
  font-family: "Anek Tamil", sans-serif;
  font-weight: 900;
  font-weight: bolder;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.guidelines{
  padding-bottom: 4rem;
  text-align: justify;
}
.instructionsHeading{
    color: blue;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 700;
}

.instructionsHeading span{
    color: orange;
}

.instructionsContent{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
    line-height: 2.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.submissionLink {
  padding-top: 1rem;
  
  
}
@media (max-width: 768px) { 
  .submissionLink{
  padding-top: 0.8rem;  
  word-wrap: break-word; 
  max-width: 100%; 
}
}
.submitDigestText{
    color: blue;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 650;
}

.submissionGuidelinesText{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
    line-height: 2.5rem;
    padding-left: 3rem;
}

.submissionGuidelinesText p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
    line-height: 2.5rem;
}
.submissionGuidelinesTextConditions{
  padding-left: 1.5rem;
}

.submissionTemplate button {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 1rem;
  font-weight: 800;
  justify-content: center;
  line-height: 1rem;
  margin: 0;
  min-height: 1rem;
  outline: none;
  overflow: visible;
  padding: 1rem 1.5rem;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .submissionTemplate button {
    padding: 1rem 1.6rem;
  }
}

.submissionTemplate button:before,
.submissionTemplate button:after {
  border-radius: 1.5rem;
}

.submissionTemplate button:before {
  background-color: rgba(249, 58, 19, .32);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.submissionTemplate button:after {
  background-color: initial;
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.submissionTemplate button:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
}

    /* .submission-template button:active:not(:disabled) {
    color: #ccc;
    } */

.submissionTemplate button:active:not(:disabled):after {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.submitButton{
    position: absolute;
    color: white;
    background-color: blue;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem;
    box-shadow: 0 0 0.1rem 0.1rem rgb(0,0,0);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    min-height:1.5rem; 
    min-width: 4rem;
}

.submitButton:hover{
    background-color: rgb(1, 1, 117);
    transition: 0.5s;
}

.submitButton:focus {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 4px #05006c;
    transition: 0.5s;
}

.button:active {
    background-color: rgb(1, 1, 117);
}
.submitButtonArea{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}