.header {
    text-align: center;
    font-family: "Anek Tamil", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    color: blue;
    font-size: clamp(24px, 5vw, 50px);
}

.header span {
    color: orange;
}

.studentAwardSection {
    // padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    img {
        object-fit: contain;
        width: 100%;  // Ensures full width for mobile
        max-width: 80vw; // Caps width on larger screens
        height: auto; 
    }

    @media (max-width: 768px) {
        img {
            max-width: 90vw; // Makes the flyer more visible on small screens
        }
    }
}

.text-center {
    text-align: center;
    padding: 0 5%; // Adds spacing on smaller screens
}

button {
    width: 100%;
    max-width: 200px; // Keeps the button a reasonable size
    text-align: center;
}

.anekTamilSponsors {
    text-align: center;
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    color: blue;
    font-size: 30px;

    h2 span {
        color: orange;
    }
}

.anekTamilAboutUs {
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    color: blue;
    font-size: 2rem;
    padding-top: 0%;
    padding-bottom: 3%;
    padding-left: 2.5rem;
    text-align: center;

    h2 span {
        color: orange;
    }
}

.anekTamilAboutUsInfo {
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings: "wdth" 97;
    font-size: 20px;
    color: rgb(53, 49, 49);
    padding-left: 1%;
}

.aboutUsInfo p span {
    font-weight: bold;
    font-size: 1.4rem;
}

.anekTamilAboutUsPara {
    font-family: "Anek Tamil", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-align:justify;
    font-variation-settings: "wdth" 97;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
    padding-left: 1%;
    padding:1rem 0rem 3rem 3rem;
    text-justify:distribute;
}



@media screen and (max-width: 400px) {
    .anekTamilAboutUs {
        font-family: "Anek Tamil", sans-serif;
        font-optical-sizing: auto;
        font-weight: 900;
        font-style: normal;
        font-variation-settings: "wdth" 97;
        color: blue;
        font-size: 1.3rem;
        padding-top: 3%;
        padding-left: 1%;
        padding-right: 1%;
        text-align: center;
    
        h2 span {
            color: orange;
        }
    }

    .aboutUsInfo p span {
        font-weight: bold;
        font-size: .9rem;
    }
    
    .anekTamilAboutUsPara {
        font-family: "Anek Tamil", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        text-align: start;
        font-variation-settings: "wdth" 97;
        font-size: .8rem;
        color: rgb(53, 49, 49);
        padding-left: 1%;
        padding-right: 1%;
        padding:0 .5rem .5rem .5rem;
    }
  }
  
  .submissionGuidelinesText{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: 4rem; 
    text-align: justify;
    
    list-style-type: disc;

    @keyframes blink {
      0% { opacity: 1; }
      50% { opacity: 0; }
      100% { opacity: 1; }
  }

  .blink {
      animation: blink 2s infinite;
      color: red; /* You can change the color if you want */
  }

}

.boldText{
    font-weight: 700;
}

.aboutUs{
    padding: 0rem 3rem 1rem 0rem;
}

.aboutUsInfo{
    text-align: justify;
}
.instructionsHeading{
    color: blue;
    text-align: start;
    padding-left: 3%;
    padding-bottom: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 700;
}
.instructionsHeading span{
    color: orange;
}

.eligibility{
    padding: 0rem 3rem 1rem 0rem;
}

.lowerText{
    color: black;
    text-align: start;
    padding: 2rem 0rem 2rem 3%;
    padding-left: 3%;
    padding-bottom: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
}

.nearFooterText{
    color: black;
    text-align: start;
    padding: 2rem 0rem 2rem 3%;
    padding-left: 3%;
    padding-bottom: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
}

.nearFooterText p{
    font-style: italic;
}