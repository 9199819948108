.navbar {
  display: flex;
  flex-direction: column;
  background-color: #379337;
  font-family: Arial, sans-serif;
  font-size: 14px;

  .topBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    background-color: white;

    .hamburger {
      display: none;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .navItems {
    padding: 0.7rem 0;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    color: #f9f9f9;

    &.showMenu {
      display: flex;
    }

    .navItem {
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s, border-radius 0.3s;
      border-radius: 8px;
      &:hover {
        background-color: #0f8b18;
        border-radius: 12px;
        font-weight: 700;
      }
    }

    .dropdown {
      position: relative;

      &:hover .dropdownContent {
        display: block;
        z-index: 1000;
      }

      .dropdownContent {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        min-width: 200px;
        border-radius: 10px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        overflow: hidden; /* Ensure content fits inside the rounded edges */
        animation: fadeIn 0.3s ease-in-out;

        li {
          padding: 12px 16px;
          display: block;
          color: #333;
          cursor: pointer;
          transition: background-color 0.3s;
          font-weight: 300;
          &:hover {
            background-color: #d2d2d2;
            
          }
        }
      }
    }
  }

  @media (max-width: 980px) {
    .topBar {
      .hamburger {
        display: flex;
        z-index: 10000;
        padding-left: 0.7rem;
      }
      .logos, .rightLogos {
        display: none;
      }
    }

    .navItems {
      display: none;
      flex-direction: column;
      width: 100%;
      .navItem {
        text-align: center;
        padding: 15px;
        border-radius: 0; /* No curve in mobile view */
      }
      .dropdownContent {
        z-index: 10000;
        position: relative;
        box-shadow: none;
        border-radius: 0; /* Flatten edges for mobile */
        li {
          padding: 15px;
        }
      }
    }
  }

  @media (min-width: 981px) {
    .navItems {
      flex-wrap: nowrap; /* Ensure no wrapping after 980px */
      font-size: 0.875rem; /* Smaller text size for larger screens (14px) */
    }
  }

  /* Fade-in animation for dropdown */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
